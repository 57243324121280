<template>
  <div>
    <div v-if="isProfileSelected">
      <dashboard-therapist v-if="activeRole === 'therapist'" />
      <dashboard-patient v-if="activeRole === 'patient'" />
    </div>
    <select-profile v-if="!isProfileSelected" />
    <dialog-accept-terms v-if="!isAcceptedTerms" />
  </div>
</template>

<script>
export default {
  components: {
    DashboardTherapist: () =>
      import("@/domains/dashboard/presentation/components/DashboardTherapist"),
    DashboardPatient: () =>
      import("@/domains/dashboard/presentation/components/DashboardPatient"),
    DialogAcceptTerms: () =>
      import("@/domains/dashboard/presentation/components/DialogAcceptTerms"),
    SelectProfile: () =>
      import("@/domains/dashboard/presentation/components/SelectProfile"),
  },
  computed: {
    isTherapist() {
      return this.$store.getters["login/isTherapist"];
    },
    isAcceptedTerms() {
      return this.$store.getters["login/termsAndPrivacyAccepted"];
    },

    activeRole() {
      return this.$store.getters["login/activeRole"];
    },

    isProfileSelected() {
      return this.$store.getters["login/activeRole"] !== null;
    },
  },
  created() {
    this.loadAppointmentDetails();
  },
  methods: {
    loadAppointmentDetails() {
      const storedData = localStorage.getItem("appointmentDetails");
      if (storedData) {
        const appointment = JSON.parse(storedData);
        console.log("Appointment", appointment);
        this.$router.push({
          path: "/appointments-patient/new",
          query: { appointmentId: appointment.appointmentId },
        });
      } else {
        console.log("No appointment details found in localStorage.");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
